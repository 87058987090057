import React from "react"
import { graphql } from "gatsby"

import { useSiteMetadata } from "../hooks/use-site-metadata"

import SEO from "../components/SEO"

import Layout from "../components/layouts/Layout"
import Box from "../components/layouts/Box"
import Grid from "../components/layouts/Grid"

import CategoryList from "../components/molecules/CategoryList"
import Product from "../components/molecules/Product"

import Heading from "../components/atoms/Heading"

const IndexPage = ({ data }, props) => {
  const { description } = useSiteMetadata()

  const {
    sanityHomepage: { products },
  } = data

  return (
    <Layout showHero={true}>
      <SEO title={description} />
      <Grid gridRowGap={[4, 6]}>
        <Box gridColumn={["span 12"]}>
          <Box
            as="img"
            width={["100%"]}
            src={"/images/offline.jpg"}
            alt="Onze webshop is even met vakantie, shop lokaal!"
          />
        </Box>
        {/* <CategoryList />
        {products.length > 0 &&
          <Box gridColumn={["span 4", "span 8", "span 12"]}>
            <Heading as="h2" fontSize={["h4"]} mb={[0]} textAlign="center">
              Producten in de kijker
            </Heading>
          </Box>
        }

        {products.map(product => {
          return (
            <Product
              key={product.id}
              product={product}
              gridColumn={["span 4", "span 4", "span 3"]}
            />
          )
        })} */}
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query {
    sanityHomepage {
      id
      products {
        id
        title
        category {
          title
        }
        slug {
          current
        }
        pricing {
          price
          tax {
            rate
          }
        }
        poster {
          asset {
            localFile {
              childImageSharp {
                fluid(maxWidth: 260) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
