import React from "react"

import styled from "styled-components"
import { color, typography } from "styled-system"
import { rgba } from "polished"

import currency from "currency.js"
import { priceFormatOpts } from "../../utils/helpers"

const StyledPriceTag = styled.span`
  font-weight: 600;
  line-height: 1;

  ${color}
  ${typography}

  em {
    color: ${props => rgba(props.theme.colors.dark, 0.5)};
    display: block;
    font-family: ${props => props.theme.font.heading};
    font-size: ${props => props.theme.fontSizes[1]};
    line-height: 1;
    margin-top: ${props => props.theme.space[2]};
  }
`

const Price = props => {
  const fontSize = props.small ? 2 : 4

  return (
    <StyledPriceTag color="brand.primary" fontSize={fontSize} {...props}>
      {currency(props.price, priceFormatOpts).format(true)}
      {props.vat && <em>(inclusief BTW)</em>}
    </StyledPriceTag>
  )
}

export default Price
