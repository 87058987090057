import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import styled from "styled-components"
import { border, color, layout, space } from "styled-system"

import Heading from "../atoms/Heading"
import Box from "../layouts/Box"

import { createSlug } from "../../utils/helpers"

const StyledLink = styled(Link)`
  align-items: center;
  border: 0;
  height: 100%;

  ${color}
  ${border}
  ${space}
  ${layout}

  &:hover {
    background-color: ${props => props.theme.colors.mint};
    > * {
      color: ${props => props.theme.colors.brand.primary};
    }
  }
`

const StyledTitle = styled(Heading)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const CategoryList = () => {
  const data = useStaticQuery(graphql`
    query {
      allSanityCategory {
        edges {
          node {
            id
            title
            icon
            show
          }
        }
      }
    }
  `)

  const categories = data.allSanityCategory.edges.filter(({ node }) => node.show)

  return (
    <>
      {categories.map(({ node }) => {
        const slug = `/${createSlug(node.title)}`
        return (
          <Box key={node.id} gridColumn={["span 4"]} alignItems="center">
            <StyledLink to={slug} bg="mint" display="flex" py={2} px={4}>
              <Box
                as="img"
                src={`/icons/${node.icon}`}
                alt={node.title}
                width="60"
                mr={[4]}
              />
              <StyledTitle as="h2" fontSize={3} m={0} lineHeight={1}>
                {node.title}
              </StyledTitle>
            </StyledLink>
          </Box>
        )
      })}
    </>
  )
}

export default CategoryList
